import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Link } from 'gatsby';

const Citations = () => (
  <Layout title="Home">
    <Section>
      <Container>
        <Row>
          <Column className="citation" size={10}>
            <h3>Citations</h3>
            <ul>
              {/* CITATIONS BELOW ALL RETURN TO THE CAROUSEL */}
              <li id="cite1">
                <a href="/health-equity#slidecites">1.</a> Public Health Across
                Sectors:{' '}
                <Link to="https://www.path.org/articles/hidden-problem-drives-100-million-people-poverty/">
                  https://www.path.org/articles/hidden-problem-drives-100-million-people-poverty/
                </Link>
                <a href="#slidecites" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite2">
                <a href="/health-equity#slidecites">2.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/news-room/fact-sheets/detail/noncommunicable-diseases">
                  https://www.who.int/news-room/fact-sheets/detail/noncommunicable-diseases
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              <li id="cite3">
                <a href="/health-equity#slidecites">3.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/health-topics/quality-of-care#tab=tab_1">
                  https://www.who.int/health-topics/quality-of-care#tab=tab_1
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              <li id="cite4">
                <a href="/health-equity#slidecites">4.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/health-topics/quality-of-care#tab=tab_1">
                  https://www.who.int/health-topics/quality-of-care#tab=tab_1
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              <li id="cite5">
                <a href="/health-equity#slidecites">5.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/data/gho/data/themes/topics/sdg-target-3_4-noncommunicable-diseases-and-mental-health">
                  https://www.who.int/data/gho/data/themes/topics/sdg-target-3_4-noncommunicable-diseases-and-mental-health
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              <li id="cite6">
                <a href="/health-equity#slidecites">6.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/health-topics/environmental-health#tab=tab_1">
                  https://www.who.int/health-topics/environmental-health#tab=tab_1
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              <li id="cite7">
                <a href="/health-equity#slidecites">7.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/health-topics/environmental-health#tab=tab_2">
                  https://www.who.int/health-topics/environmental-health#tab=tab_2
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              <li id="cite8">
                <a href="/health-equity#slidecites">8.</a> Raghupathi, V.,
                Raghupathi, W. The influence of education on health: an
                empirical assessment of OECD countries
                <br />
                for the period 1995-2015. Arch Public Health 78, 20 (2020).{' '}
                <a href="https://doi.org/10.1186/s13690-020-00402-5">
                  https://doi.org/10.1186/s13690-020-00402-5
                </a>
                <a
                  href="/health-equity#slidecites"
                  className="citation__return"
                >
                  Return
                </a>
              </li>
              {/* CITATIONS BELOW ALL RETURN TO THE ACCORDION */}
              <li id="cite9">
                <a href="/health-equity#accordion">9.</a> NEJM Catalyst:{' '}
                <a href="https://catalyst.nejm.org/doi/full/10.1056/CAT.17.0312">
                  https://catalyst.nejm.org/doi/full/10.1056/CAT.17.0312
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite10">
                <a href="/health-equity#accordion">10.</a> Kumar S, Preetha G.
                Health promotion: an effective tool for global health. Indian J
                Community Med. 2012 Jan; 37(1):5-12.
                <br /> doi: 10.4103/0970-0218.94009. PMID: 22529532; PMCID:
                PMC3326808.
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite11">
                <a href="/health-equity#accordion">11.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/news/item/13-12-2017-world-bank-and-who-half-the-world-lacks-access-to-essential-health-services-100-million-still-pushed-into-extreme-poverty-because-of-health-expenses">
                  https://www.who.int/news/item/13-12-2017-world-bank-and-who-half-the-world-lacks-access-to-essential-health-services-100-million-still-pushed-into-extreme-poverty-because-of-health-expenses
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              {/* ADD CITE 12 */}
              <li id="cite12">
                <a href="/health-equity#accordion">12.</a> Institute of
                Medicine, Committee on Monitoring Access to Personal Health Care
                Services. Access to health care in America. Washington, DC:
                National Academy Press; 1993.{' '}
                <a href="https://www.ncbi.nlm.nih.gov/books/NBK235882/">
                  https://www.ncbi.nlm.nih.gov/books/NBK235882/
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>

              <li id="cite13">
                <a href="/health-equity#accordion">13.</a> The World Health
                Organization:{' '}
                <a href="https://apps.who.int/iris/bitstream/handle/10665/272465/9789241513906-eng.pdf">
                  https://apps.who.int/iris/bitstream/handle/10665/272465/9789241513906-eng.pdf
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite14">
                <a href="/health-equity#accordion">14.</a> Healthy People 2030:{' '}
                <a href="https://health.gov/healthypeople/priority-areas/social-determinants-health">
                  https://health.gov/healthypeople/priority-areas/social-determinants-health
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>

              <li id="cite15">
                <a href="/health-equity#accordion">15.</a>
                Bonaccorsi G, Manzi F, Del Riccio M, Setola N, Naldi E, Milani
                C, Giorgetti D, Dellisanti C, Lorini C. Impact of the Built
                Environment and the Neighborhood in Promoting the Physical
                Activity and the Healthy Aging in Older People: An Umbrella
                Review. Int J Environ Res Public Health. 2020 Aug
                23;17(17):6127. doi: 10.3390/ijerph17176127. PMID: 32842526;
                PMCID: PMC7504170.
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite16">
                <a href="/health-equity#accordion">16.</a> The United Nations:{' '}
                <a href="https://www.un.org/development/desa/en/news/population/2018-revision-of-world-urbanization-prospects.html#:~:text=News-,68%25%20of%20the%20world%20population%20projected%20to%20live%20in,areas%20by%202050%2C%20says%20UN&text=Today%2C%2055%25%20of%20the%20world's,increase%20to%2068%25%20by%202050.">
                  https://www.un.org/development/desa/en/news/population/2018-revision-of-world-urbanization-prospects.html
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite17">
                <a href="/health-equity#accordion">17.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/news-room/fact-sheets/detail/sanitation">
                  https://www.who.int/news-room/fact-sheets/detail/sanitation
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite18">
                <a href="/health-equity#accordion">18.</a> The World Health
                Organization:{' '}
                <a href="https://www.who.int/news/item/12-05-2016-air-pollution-levels-rising-in-many-of-the-world-s-poorest-cities">
                  https://www.who.int/news/item/12-05-2016-air-pollution-levels-rising-in-many-of-the-world-s-poorest-cities
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite19">
                <a href="/health-equity#accordion">19.</a>Hahn RA, Truman BI.
                Education Improves Public Health and Promotes Health Equity. Int
                J Health Serv. 2015;45(4):657-78.
                <br /> doi: 10.1177/0020731415585986. Epub 2015 May 19. PMID:
                25995305; PMCID: PMC4691207.
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite20">
                <a href="/health-equity#accordion">20.</a> The World Economic
                Forum:{' '}
                <a href="https://www.weforum.org/agenda/2020/07/unesco-education-inclusion-inequalities/">
                  https://www.weforum.org/agenda/2020/07/unesco-education-inclusion-inequalities/
                </a>
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
              <li id="cite21">
                <a href="/health-equity#accordion">21.</a>Lansford JE, Dodge KA,
                Pettit GS, Bates JE. A Public Health Perspective on School
                Dropout and Adult Outcomes: A Prospective Study of Risk and
                Protective Factors From Age 5 to 27 Years. J Adolesc Health.
                2016 Jun;58(6):652-8. doi: 10.1016/j.jadohealth.2016.01.014.
                Epub 2016 Mar 19. PMID: 27009741; PMCID: PMC4877222.
                <a href="/health-equity#accordion" className="citation__return">
                  Return
                </a>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default Citations;
